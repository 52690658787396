import $ from 'jquery';

const route = window.appConfig && window.appConfig.route ? window.appConfig.route : '';
let baseUrl = window.appConfig && window.appConfig.baseUrl ? window.appConfig.baseUrl : '';
if (baseUrl.length === 0) {
    throw 'baseUrl is not found in config';
}
const mgnUrl = baseUrl + '/.rest/genobuy/getComponents.json?url=/' + route;

const themeContext = window.location.pathname.split('/')[1];
const setContextTheme = themeContext !== 'control';
window.appConfig.themeContext = themeContext;

$(document).ready(function() {
    let componentHTML = [];
    let jsUrls = [];
    let cssUrls = [];
    const el = $('#app');

    const sprite = $('<div></div>');
    sprite.hide();
    sprite.insertBefore(el);
    sprite.load(baseUrl + '/.resources/portal-module/webresources/assets/symbol/svg/sprite.symbol.svg');

    $.getScript().always(() => {
        $.get(
            mgnUrl,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
            {},
        )
            .then(response => {
                return $.getScript(
                    baseUrl +
                        '/.resources/portal-module/webresources/vendor/js/webcomponentsjs/webcomponents-loader.js',
                ).then(() => response);
            })

            .then(response => {
                const is_ie = navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1;
                // const is_Edge= window.navigator.userAgent.indexOf("Edge") > -1

                if (is_ie) {
                    const l = document.createElement('link');
                    l.type = 'text/css';
                    l.rel = 'stylesheet';
                    l.href = baseUrl + '/.resources/portal-module/webresources/css/headless.css';
                    $('head').append(l);
                    return response;
                } else {
                    return $.get(baseUrl + '/.resources/portal-module/webresources/css/headless.css').then(styles => {
                        const s = document.createElement('style');
                        s.id = 'globalStyles';
                        s.innerHTML = styles;
                        $('head')[0].appendChild(s);
                        return response;
                    });
                }
            })

            .then(response => {
                componentHTML = response.components;
                jsUrls = [...response.resources.scripts];
                cssUrls = response.resources.styles.reverse();

                jsUrls.forEach(url => {
                    const s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.src = url;
                    s.setAttribute('defer', 'defer');
                    $('head').append(s);
                });

                cssUrls.forEach(url => {
                    const l = document.createElement('link');
                    l.type = 'text/css';
                    l.rel = 'stylesheet';
                    l.href = url;
                    $('head').append(l);
                });

                const components = $(componentHTML.join(''));
                if (setContextTheme) {
                    components.find('a[href*="/control"]').each(function(index, element) {
                        const linkUrl = element.href || '';

                        element.href = linkUrl.replace('/control', `/${themeContext}/control`);
                    });
                }

                el.html(components);
            });
    });
});
